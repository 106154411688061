import React, { useState } from "react";
import "./LinkButtons.css";
import MyModal from "./MyModal";

const LinkButtons = ({ btnName, dataLinks }) => {
  const [open, setOpen] = useState(false);
  
  return (
    <>
      <button
        className="link-container xsm:py-0 xsm:text-xxsm px-1 py-2 text-base sm:text-lg"
        rel="noopener noreferrer"
        onClick={() => setOpen(true)}
      >
        {btnName}
      </button>

      <>
      <MyModal
        btnName={btnName}
        open={open}
        setOpen={setOpen}
        dataLinks={dataLinks}
      />        
      </>
    </>
  );
};

export default LinkButtons;

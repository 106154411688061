import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { randomColors } from "./utils/styles";
import { PiVinylRecordDuotone } from "react-icons/pi";
import { CiMusicNote1 } from "react-icons/ci";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./App.css";
import { Loader } from "./components";

const Home = lazy(() => import("./components/Home/Home"));
const RainEffect = lazy(() =>
  import("./components/Effects/RainEffect/RainEffect")
);

const App = () => {
  const [isBgColor, setIsBgColor] = useState(randomColors[0]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShowNote, setIsShowNote] = useState(false);
  const audioRef = useRef(null);
  const audioPlayerRef = audioRef.current?.audio?.current;
  const [isLeaving, setIsLeaving] = useState(false);

  const bgVideoSrc1080 =
    "https://firebasestorage.googleapis.com/v0/b/ibrohimbek-links.appspot.com/o/my-links-assets%2FbgVideo1080.mp4?alt=media&token=be0da715-f4c0-40e6-92a6-f5d4df9fb75e";

  const bgPlaceHolderImage =
    "https://firebasestorage.googleapis.com/v0/b/ibrohimbek-links.appspot.com/o/my-links-assets%2FbgPlaceHolderImage.webp?alt=media&token=951cb2d8-fdb7-4ffd-8a4d-16f8131d25ef";

  const bgPhoneImage =
    "https://firebasestorage.googleapis.com/v0/b/ibrohimbek-links.appspot.com/o/my-links-assets%2FbgPhoneImage.webp?alt=media&token=df75f054-b428-44a1-b203-df3106aaa638";

  const bgMusicSrc =
    "https://firebasestorage.googleapis.com/v0/b/ibrohimbek-links.appspot.com/o/my-links-assets%2FinterstellarMp3.mp3?alt=media&token=38285f14-1aaf-452d-bb13-b0860c4beb97";

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * randomColors.length);
      setIsBgColor(randomColors[randomIndex]);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePlay = () => {
    if (audioPlayerRef) {
      if (audioPlayerRef.paused) {
        setIsPlaying(true);
        audioPlayerRef.play();
        audioPlayerRef.volume = 1;
      } else {
        const volumeInterval = setInterval(() => {
          if (audioPlayerRef.volume > 0.1) {
            audioPlayerRef.volume -= 0.1;
          } else {
            clearInterval(volumeInterval);
            audioPlayerRef.pause();
            setIsPlaying(false);
          }
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (audioPlayerRef && audioPlayerRef.paused !== undefined) {
      setIsPlaying(!audioPlayerRef.paused);
    }

    if (isLeaving && audioPlayerRef) {
      audioPlayerRef.volume -= 0.1;
      audioPlayerRef.pause();
      setIsPlaying(false);
    }
  }, [audioPlayerRef, isLeaving]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setIsLeaving(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const noteTimeout = setTimeout(() => {
      setIsShowNote(isPlaying);
    }, 3000);

    return () => {
      clearTimeout(noteTimeout);
    };
  }, [isPlaying]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Ibrohimbek Alisherov links. Mening ijtimoiy tarmoqlarim"
        />
      </Helmet>
      <Suspense fallback={<Loader/>}>
        <div className="select-none flex-col bg-[#344955] flex overflow-hidden z-40 justify-center items-center flex-auto w-full h-screen">
          <div className="w-full h-auto flex justify-start items-start">
            <div
              onClick={handlePlay}
              className={`${
                isPlaying ? "spin-player" : "spin-stop"
              } cursor-pointer z-50 border-none outline-none rounded-full overflow-hidden m-1`}
            >
              <PiVinylRecordDuotone
                style={{
                  background: isBgColor,
                  transitionDuration: "3s",
                  transform: "ease-in-out",
                  borderRadius: "100%",
                  outline: "none",
                }}
                className="text-3xl sm:text-4xl border-none rounded-full text-white"
              />
            </div>

            {isShowNote && (
              <>
                <CiMusicNote1
                  style={{ color: isBgColor }}
                  className="music-note music-note-1 text-2xl sm:text-3xl"
                />
                <CiMusicNote1
                  style={{ color: isBgColor }}
                  className="music-note music-note-2 text-2xl sm:text-3xl"
                />
                <CiMusicNote1
                  style={{ color: isBgColor }}
                  className="music-note music-note-3 text-2xl sm:text-3xl"
                />
              </>
            )}
          </div>
          <video
            src={bgVideoSrc1080?.length > 0 && bgVideoSrc1080}
            type="video/mp4"
            autoPlay={true}
            muted={true}
            loop
            playsInline={false}
            webkit-playsinline={false}
            controls={false}
            controlsList="nodownload nodrag noremoteplayback"
            disablePictureInPicture
            className="h-full sm:flex hidden bg-no-repeat bg-center z-10 w-screen absolute object-cover"
            preload="auto"
            poster={bgPlaceHolderImage?.length > 0 && bgPlaceHolderImage}
          ></video>

          <img
            src={bgPhoneImage}
            className="h-screen sm:hidden flex bg-no-repeat bg-center z-10 w-screen absolute object-cover"
            alt=""
          />
          <Home />
          <>
            <RainEffect />
          </>
        </div>
      </Suspense>

      <div className="-z-50 opacity-0 hidden absolute left-0">
        <AudioPlayer
          src={bgMusicSrc?.length > 0 && bgMusicSrc}
          loop={true}
          autoPlay={true}
          ref={audioRef}
          controls={false}
          showSkipControls={false}
          showJumpControls={false}
          showDownloadProgress={false}
          showFilledProgress={false}
          showFilledVolume={false}
          autoPlayAfterSrcChange={true}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          className="-z-50 hidden opacity-0"
        />
      </div>
    </>
  );
};

export default App;

export const randomColors = [
  "#ffc300",
  "#aacc00",
  "#6798c0",
  "#5c0099",
  "#eefc57",
  "#ffc6ff",
  "#06d6a0",
  "#4895ef",
  "#c9ada7",
  "#d5bdaf",
  "#90e0ef",
  "#4361ee",
  "#ff7f50",
  "#00ced1",
  "#ff1493",
  "#7fffd4",
  "#9932cc",
  "#3cb371",
  "#ff00ff",
  "#ffa500",
  "#00ff7f",
  "#8a2be2",
  "#ff4500",
  "#e74c3c",
  "#3498db",
  "#f39c12",
  "#2ecc71",
  "#9b59b6",
  "#1abc9c",
  "#f1c40f",
  "#27ae60",
  "#c0392b",
  "#2980b9",
  "#e67e22",
  "#ffd700",
  "#00ffff",
  "#fa8072",
  "#808000",
  "#8b008b",
  "#228b22",
  "#ff69b4",
  "#dc143c",
  "#00fa9a",
  "#da70d6",
  "#ff8c00",
  "#00ff00",
  "#9932cc",
  "#4682b4",
  "#f08080",
  "#778899",
  "#8b4513",
  "#7b68ee",
];

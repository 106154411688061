import React from "react";
import {
  FaTelegram,
  FaGithub,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa";
import { FaSquarePhone } from "react-icons/fa6";
import "./SocialLinks.css";
import { AnimatePresence, motion } from "framer-motion";





const SocialLinks = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="flex main-icon-container social-links w-auto py-1 px-2 rounded-2xl justify-center space-x-2 md:space-x-4"
      >
        <a
          href="https://t.me/memberofns"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-container xsm:text-2xl text-3xl sm:text-4xl hover:scale-110 duration-200 ease-linear "
        >
          <FaTelegram className="icon" />
        </a>
        <a
          href="https://www.instagram.com/ibek0127"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-container xsm:text-2xl text-3xl sm:text-4xl hover:scale-110 duration-200 ease-linear "
        >
          <FaInstagramSquare className="icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/ibrohimbek-dev"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-container xsm:text-2xl text-3xl sm:text-4xl hover:scale-110 duration-200 ease-linear "
        >
          <FaLinkedin className="icon" />
        </a>
        <a
          href="https://github.com/ibrohimbek-dev"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-container xsm:text-2xl text-3xl sm:text-4xl hover:scale-110 duration-200 ease-linear "
        >
          <FaGithub className="icon" />
        </a>
        <a
          href="tel:+8210-7699-6622"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-container xsm:text-2xl text-3xl sm:text-4xl hover:scale-110 duration-200 ease-linear "
        >
          <FaSquarePhone className="icon" />
        </a>
      </motion.div>
    </AnimatePresence>
  );
};

export default SocialLinks;

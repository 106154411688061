import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import LinkButtons from "./LinkButtons";

const MainLinkButton = () => {
	const personalLinks = [{ title: "My Personal Website!", link: "soon" }];
	const socialLinks = [
		{
			title: "My Social Link Tree!",
			link: "https://linktr.ee/dev_ibrohimbek",
		},
	];
	const learnEnglish = [
		{
			title: "My English Link Tree!",
			link: "https://linktr.ee/ns_uzb",
		},
	];

	const seeMyPortfolio = [
		{ title: "My Portfolio Link Tree!", link: "https://linktr.ee/ibrohimbek_dev" },
	];

	const learnCoding = [
		{ title: "My Coding Link Tree!", link: "https://linktr.ee/devcode0101" },
	];

	const donateWith = [
		{ title: "Donate with PayPal!", link: "soon" },
		{ title: "Donate with Stripe!", link: "soon" },
	];

	return (
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0, y: -50 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.8 }}
				className="space-y-2 p-0 sm:pb-4 w-full h-auto flex flex-col justify-center items-center"
			>
				<LinkButtons dataLinks={personalLinks} btnName={"Visit My Website!"} />
				<LinkButtons dataLinks={socialLinks} btnName={"My Social Links!"} />
				<LinkButtons dataLinks={learnEnglish} btnName={"Learn English!"} />
				<LinkButtons dataLinks={seeMyPortfolio} btnName={"See My Portfolio!"} />
				<LinkButtons dataLinks={learnCoding} btnName={"Learn Coding!"} />
				<LinkButtons dataLinks={donateWith} btnName={"$ Donate $"} />
			</motion.div>
		</AnimatePresence>
	);
};

export default MainLinkButton;

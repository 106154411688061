import React from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import "./MyModal.css";

const MyModal = ({ open, setOpen, btnName, dataLinks }) => {
  const handleClose = () => setOpen(false);

  const comingSoon = () => {
    alert("Coming Soong!");
    setOpen(false);
  };
  return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="absolute modal-container flex flex-col justify-evenly h-auto sm:w-auto overflow-hidden outline-none border-none rounded-xl text-textColor top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-24 p-4">
				<div className="space-y-3 w-full">
					<div className="flex justify-between items-center">
						<p className="text-xxsm font-normal">{btnName}</p>
						<IoMdClose
							onClick={handleClose}
							className="cursor-pointer hover:scale-110 duration-200 ease-linear text-base sm:text-2xl"
						/>
					</div>

					<div className="w-full space-y-3 flex flex-col items-center justify-evenly">
						{dataLinks?.map((data, index) => (
							<a
								className="sm:text-base font-normal text-sm  border border-gray-500/20 duration-150 ease-linear transition-all w-full text-center rounded-md hover:bg-[#000] hover:text-white"
								href={data?.link === "soon" ? "#" : data?.link}
								target="_blank"
								without
								rel="noreferrer"
								key={index}
								onClick={() => {
									if (data?.link === "soon") {
										comingSoon();
									}
								}}
							>
								{data?.title}
							</a>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default MyModal;

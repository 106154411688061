import React from "react";
import "./RainEffect.css";
import { TbBrandKotlin, TbBrandNodejs, TbBrandPython } from "react-icons/tb";
import { FaBootstrap, FaCss3, FaHtml5, FaJava, FaReact } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { SiCplusplus, SiExpress, SiTailwindcss } from "react-icons/si";
import { SiCsharp } from "react-icons/si";
import { DiMongodb, DiRuby } from "react-icons/di";
import { BiLogoGoLang, BiLogoPhp } from "react-icons/bi";
import { TbBrandTypescript, TbBrandSwift } from "react-icons/tb";
import { FaRust } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import { randomColors } from "../../../utils/styles";

const RainEffect = () => {
  const [isIconColors, setIsIconColors] = useState([]);

  useEffect(() => {
    const colors = Array.from(
      { length: randomColors.length },
      () => randomColors[Math.floor(Math.random() * randomColors.length)]
    );
    setIsIconColors(colors);
  }, []);

  const iconsArray = [
    <IoLogoJavascript />,
    <FaJava />,
    <TbBrandPython />,
    <SiCplusplus />,
    <SiCsharp />,
    <DiRuby />,
    <BiLogoPhp />,
    <TbBrandTypescript />,
    <TbBrandSwift />,
    <BiLogoGoLang />,
    <TbBrandKotlin />,
    <SiExpress />,
    <TbBrandNodejs />,
    <DiMongodb />,
    <FaRust />,
    <FaHtml5 />,
    <FaCss3 />,
    <SiTailwindcss />,
    <FaBootstrap />,
    <FaReact />,
  ];

  return (
    <div className="snowflakes" aria-hidden="true">
      {iconsArray?.map((icon, index) => (
        <div
          className="snowflake text-base sm:text-lg"
          key={index}
          style={{ color: isIconColors[index] }}
        >
          {icon}
        </div>
      ))}
    </div>
  );
};

export default RainEffect;

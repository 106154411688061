import React, { useState, useEffect } from "react";
import SocialLinks from "../SocialLinks/SocialLinks";
import "./Bio.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import TextEffect from "../Effects/TextEffect/TextEffect";

const Bio = () => {
  const [isShowTooltip, setIsShowToolTip] = useState(false);

  const handleToolTipToggle = () => {
    setIsShowToolTip(!isShowTooltip);
  };

  const myImage =
    "https://firebasestorage.googleapis.com/v0/b/ibrohimbek-links.appspot.com/o/my-links-assets%2Fme-linkedin-webp.webp?alt=media&token=3d299a40-7f5a-4762-8956-33a0bbd35db8";

  useEffect(() => {
    // Optional: You can also add a loading state while the image is being loaded
    const image = new Image();
    image.src = myImage;
    image.addEventListener("load", () => {
      // Image has been loaded
      // You can update the state or handle any other logic here
    });

    return () => {
      // Clean up event listener if component unmounts before image is loaded
      image.removeEventListener("load", () => {});
    };
  }, [myImage]);

  return (
    <div className="select-none flex justify-center items-center md:items-end flex-col h-auto w-full space-y-3">
      <div
        onMouseEnter={() => setIsShowToolTip(true)}
        onMouseLeave={() => setIsShowToolTip(false)}
        onClick={handleToolTipToggle}
        className="flex cursor-pointer justify-center items-center relative w-24 h-24 sm:w-36 sm:h-36 rounded-full"
      >
        <LazyLoadImage
          className="rounded-full w-24 h-24 sm:w-36 sm:h-36 border-[3px] border-[rgb(112,224,0)] w-ful h-fll object-cover"
          src={myImage}
          alt="my picture"
          effect="blur" // Optional: Add a blur effect while image is loading
        />
        <span className="animate-border absolute inset-0 rounded-full"></span>
        <>
          {isShowTooltip && (
            <div className="absolute -left-28 border-none rounded-tr-[23px] rounded-bl-[23px] bg-[#9dc3c2] py-1 px-3 top-0 text-[#1d3557] font-semibold">
              I'm open to work!
            </div>
          )}
        </>
      </div>

      <div className="flex justify-center flex-col items-center md:items-end">
        <p className="font-bold text-[#b4e1ff] xsm:text-sm text-xl font-mono">
          Ibrohimbek Alisherov
        </p>
        <div className="flex justify-center items-center">
          <TextEffect />
        </div>

        <SocialLinks />
      </div>
    </div>
  );
};

export default Bio;

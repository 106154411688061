import React from "react";
import "./TextEffect.css";

const TextEffect = () => {
  return (
    <main className="flex flex-col leading-0 justify-center items-center">
      <p className="text-[#b4e1ff] text-base">Hello 👋 I'm</p>
      <div className="h-6 leading-0 overflow-hidden flex text-center justify-center items-end text-base">
        <section className="text-[#ffff3f] text-base animation mt-10 space-y-2 sm:space-y-1 flex flex-col justify-center ml-1 items-center overflow-hidden">
          <p className="first">
            <span className="p-">studying in South Korea!</span>
          </p>
          <p className="first">
            <span className="p-">from Uzbekistan!</span>
          </p>
          <p className="first">
            <span className="p-">Web developer!</span>
          </p>
          <p className="first">
            <span className="p-">Ibrohimbek!</span>
          </p>
        </section>
      </div>
    </main>
  );
};

export default TextEffect;

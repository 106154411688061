import React from "react";
import Bio from "../Bio/Bio";
import "./Home.css";
import { Helmet } from "react-helmet";
import MainLinkButton from "../LinkButtons/MainLinkButton";
import Footer from "../Footer/Footer";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Ibrohimbek Alisherov links. Mening ijtimoiy tarmoqlarim"
        />
      </Helmet>
      <div
        id="homeContainer"
        className="home-container justify-center space-y-4  items-center z-50 h-full flex flex-col md:flex-row "
      >
        <Bio />
        <MainLinkButton />
      </div>

      <Footer />
    </>
  );
};

export default Home;

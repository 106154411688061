import React from "react";

const Footer = () => {
  return (
    <footer className="w-full font-mono text-white hidden md:flex text-xxsm justify-center items-center text-center absolute bottom-0 z-50 ">
      All rights reserved &copy; 2024
    </footer>
  );
};

export default Footer;
